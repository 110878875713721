<template>
  <div class="onboarding">
    <v-container fluid>
      <v-row class="my-8" style="height: 100vh">
        <v-spacer></v-spacer>
        <v-col>
          <v-card width="800" flat>
            <v-card-title>Welcome to Prospectbox.io</v-card-title>
            <v-card-text>
              <v-container>
                <p>
                  Please enter your information to finish your account creation.
                </p>
                <v-form
                  @submit.prevent="signUp"
                  ref="form"
                  class="mx-2"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="settings.name"
                        label="Company Name"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="settings.contactFirstName"
                        label="First Name"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="settings.contactLastName"
                        label="Last Name"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="selectedPlan.name"
                        label="Package"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="selectedPlan.price"
                        label="Price"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="settings.email"
                        label="Email address"
                        hint="Used when logging in to prospectbox"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="settings.password"
                        label="Password"
                        hint="Used when logging in to prospectbox"
                        persistent-hint
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div
                        id="creditCardNumber"
                        style="
                          height: 48px;
                          padding: 0;
                          border-bottom: 1px dashed #ccc;
                        "
                      ></div>
                    </v-col>
                    <v-col cols="6">
                      <div
                        id="expireDate"
                        style="
                          height: 48px;
                          padding: 0;
                          border-bottom: 1px dashed #ccc;
                        "
                      ></div>
                    </v-col>
                    <v-col cols="6">
                      <div
                        id="cvv"
                        label="CVV"
                        style="
                          height: 48px;
                          padding: 0;
                          border-bottom: 1px dashed #ccc;
                        "
                      ></div>
                    </v-col>
                    <v-col cols="12">
                      <div v-if="settings.trialMonth">
                        <strong>
                          Amount to pay now:
                          {{ selectedPlan.discountedPrice }} (50% OFF first
                          month)</strong
                        >
                      </div>
                      <div v-else>
                        <strong
                          >Amount to pay now:
                          {{ selectedPlan.priceOnly }}</strong
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <div class="text-center my-4">
                    <v-btn type="submit" rounded color="primary">
                      Create Account
                    </v-btn>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import braintree from "braintree-web";

export default {
  data() {
    return {
      settings: {},
      hostedFieldInstance: false,
      nonce: undefined,
      plans: [
        {
          id: "750",
          name: "750 prospects / mo.",
          price: "€995 / mo.",
          priceOnly: "€995",
          discountedPrice: "€497",
        },
        {
          id: "1500",
          name: "1,500 prospects / mo.",
          price: "€1,795 / mo.",
          priceOnly: "€1,795",
          discountedPrice: "€897",
        },
        {
          id: "2500",
          name: "2,500 prospects / mo.",
          price: "€3,250 / mo.",
          priceOnly: "€3,250",
          discountedPrice: "€1,625",
        },
        {
          id: "4000",
          name: "4,000 prospects / mo.",
          price: "€4,995 / mo.",
          priceOnly: "€4,995",
          discountedPrice: "€2,497",
        },
      ],
      selectedPlan: {},
    };
  },
  created() {
    this.getSettings();
    braintree.client
      .create({
        authorization: process.env.VUE_APP_BRAINTREE_AUTH,
      })
      .then((clientInstance) => {
        let options = {
          client: clientInstance,
          styles: {
            input: {
              "font-size": "14px",
            },
          },
          fields: {
            number: {
              selector: "#creditCardNumber",
              placeholder: "Enter your credit card number",
            },
            cvv: {
              selector: "#cvv",
              placeholder: "Enter CVV",
            },
            expirationDate: {
              selector: "#expireDate",
              placeholder: "00 / 0000",
            },
          },
        };

        return braintree.hostedFields.create(options);
      })
      .then((hostedFieldInstance) => {
        this.hostedFieldInstance = hostedFieldInstance;
      });
  },
  methods: {
    async getSettings() {
      try {
        const settingsResponse = await this.$http.get(
          `/onboarding/${this.$route.query.oid}`
        );
        this.settings = settingsResponse.data;
        this.selectedPlan = this.plans.find(
          (plan) => plan.id === this.settings.planId
        );
      } catch (error) {
        this.$router.push({ path: "/login" });
      }
    },
    async signUp() {
      try {
        const payload = await this.hostedFieldInstance.tokenize();
        this.settings.nonce = payload.nonce;
        await this.$http.post("/onboarding", { settings: this.settings });
        window.location = "https://portal.prospectbox.io";
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
